import React from 'react';
import { PageProps, Link, graphql } from 'gatsby';
import Img from 'gatsby-image';

import GatsbyPlaceholder from './gatsby-icon.png';
import Main from '../components/Main';

const Projects: React.FC<PageProps> = ({ data }) => (
  <Main>
    <h2>Projects</h2>
    <p>Here are some projects I&apos;ve been working on:</p>
    {data.allMarkdownRemark.edges.map((post) => (
      <div key={post.node.id}>
        <Link to={post.node.fields.slug}>
          {post.node.frontmatter.thumbnail ? (
            <Img
              fixed={post.node.frontmatter.thumbnail.childImageSharp.fixed}
            />
          ) : (
            <img
              style={{
                maxWidth: '200px',
                maxHeight: '200px',
                minWidth: '200px',
                minHeight: '200px',
              }}
              src={GatsbyPlaceholder}
            />
          )}
        </Link>
        <p>{post.node.excerpt}</p>
      </div>
    ))}
  </Main>
);

export default Projects;

export const pageQuery = graphql`
  query ProjectIndex {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/projects/" } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
            thumbnail {
              childImageSharp {
                fixed(width: 200, height: 200) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`;
